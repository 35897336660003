<template>
  <div>
    <b-card>
      <h4>Usuários por dia</h4>
      <b-card-body>
        <b-row>
          <b-col sm="4" align-self="center">
            <i2-form-date-range
              v-model="dates"
              label="Período"
              @onSelect="onSelect"
            />
          </b-col>
          <b-col sm="6">
            <organization-select v-model="search.organizations" :multiple="true" :show-label="true" />
          </b-col>
          <b-col align-self="center" sm="2" style="padding-top: 2px">
            <i2-icon-button
              icon="SearchIcon"
              variant="outline-primary"
              class="mr-1"
              @click="getProvisioningHistory()"
            />
          </b-col>
        </b-row>
        <b-row v-if="loading" style="height: 350px;" class="text-center">
          <b-col
            sm="12"
            align-self="center"
          >
            <i2-data-loading />
          </b-col>
        </b-row>
        <bar-chart v-if="!loading && hasData"
                   height="300"
                   :toolbar="true"
                   :options="{xAxysGrid: true, yAxysGrid:true, dataLabels: true}"
                   :values="series"
                   :y-label="yLabel"
                   :x-label-itens="30"
                   :x-label-rotate="true"
        />
        <b-row v-if="!loading && !hasData" style="height: 300px;" class="text-center">
          <b-col sm="12" align-self="center">
            <img :src="require('@/assets/images/icons/no-resutls.svg')"><br>
            Sem resultados
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import I2DataLoading from '@/components/i2/layout/loading/I2DataLoading'
import BarChart from '@/components/i2/Graph/BarChart.vue'
import I2FormDateRange from '@/components/i2/forms/elements/I2FormDateRange'
import moment from 'moment-timezone'
import OrganizationSelect from '@/components/app/Organization/OrganizationSelect'

export default {
  name: 'UsersPerDayGraph',
  components: {
    I2DataLoading,
    BarChart,
    I2FormDateRange,
    OrganizationSelect,
  },
  data() {
    return {
      loading: false,
      hasData: false,
      series: {},
      dates: [],
      search: {
        organizations: [],
      },
      dateFilter: {
        deviceProvisioning: {
          startDate: null,
          endDate: null,
        },
      },
      deviceProvisioning: {
        dateRange: {
          startDate: null,
          endDate: null,
        },
      },
      yLabel: {
        text: 'Quantidades',
        format: val => new Intl.NumberFormat('pt-BR').format(val),
      },
    }
  },
  created() {
    const today = new Date()
    const startDate = new Date(today.getFullYear(), (today.getMonth()), 1)
    this.dates = [startDate, today]
    this.setDateFilter(startDate, today)
    this.getProvisioningHistory()
  },
  methods: {
    onSelect(values) {
      this.setDateFilter(values.startDate, values.endDate)
    },
    setDateFilter(startDate, endDate) {
      this.dateFilter.deviceProvisioning.startDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
      this.dateFilter.deviceProvisioning.endDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
    },
    getProvisioningHistory() {
      this.loading = true
      this.$http.showLoader = false
      let unities = ''
      if (this.search.organizations) {
        unities = this.search.organizations.join(',')
      }
      const filter = `dateInitial=${this.dateFilter.deviceProvisioning.startDate}&dateFinal=${this.dateFilter.deviceProvisioning.endDate}`
      this.$http.get(`graph/user-by-day/data?${filter}&unities=${unities}`).then(response => {
        this.series = response
        this.loading = false
        this.$http.showLoader = true
        this.hasData = this.series.series[0].data.length > 0
      })
    },
  },
}
</script>

<style scoped>

</style>
