<template>
  <b-card no-body>
    <b-card-body>
      <vue-apex-charts
        ref="graph"
        type="bar"
        :height="height"
        width="100%"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>
<script>

import VueApexCharts from 'vue-apexcharts'
import { mapState } from 'vuex'

export default {
  name: 'BarChart',
  components: {
    VueApexCharts,
  },
  props: {
    height: {
      type: [Number, String],
      required: true,
      default: 400,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    toolbar: {
      type: Boolean,
      required: false,
      default: false,
    },
    values: {
      type: Object,
      required: true,
      default() {
        return {
          series: [],
          labels: [],
        }
      },
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    isStacked: {
      type: Boolean,
      default: false,
    },
    yLabel: {
      type: Object,
      required: false,
      default() {
        return {
          text: '',
          format: val => val,
        }
      },
    },
    xLabelItens: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    xLabelRotate: {
      type: [Boolean],
      required: false,
      default: false,
    },

    options: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      series: this.values.series || [],
      defaultOptions: {
        zoom: true,
        xAxysGrid: true,
        yAxysGrid: true,
        dataLabels: true,
      },
      chartOptions: {
        theme: {
          mode: 'light',
        },
        chart: {
          foreColor: '#373d3f',
          stacked: this.isStacked,
          toolbar: {
            show: this.toolbar,
          },
          redrawOnParentResize: true,
          zoom: {
            enabled: true,
            type: 'x',
            autoScaleYaxis: true,
            autoScaleXaxis: true,
            zoomedArea: {
              fill: {
                color: '#90CAF9',
                opacity: 0.4,
              },
              stroke: {
                color: '#0D47A1',
                opacity: 0.4,
                width: 1,
              },
            },
          },
        },
        tooltip: {
          theme: 'light',
        },
        colors: ['#2b9bf4', '#63b9ff'],
        plotOptions: {
          bar: {
            horizontal: this.isHorizontal,
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom

            },
          },
        },
        dataLabels: {
          enabled: true,
          hideOverflowingLabels: true,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758'],
          },
        },
        legend: {
          show: true,
          position: 'top',
          fontSize: '14px',
          fontFamily: 'Montserrat',
          horizontalAlign: 'left',
        },
        stroke: {
          show: true,
          colors: ['transparent'],
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
            rotateAlways: this.xLabelItens,
          },
          categories: this.values.labels,
          tickPlacement: 'on',
          tickAmount: 30,

        },
        yaxis: {
          title: {
            text: this.yLabel.text,
            style: {
              fontSize: '8px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              colors: undefined,
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
    }
  },
  computed: {
    ...mapState(['appConfig/layout']),
  },
  watch: {
    values(data) {
      this.series = data.series
      const newOptions = { ...this.chartOptions }
      newOptions.xaxis.categories = data.labels
      this.chartOptions = newOptions
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'appConfig/UPDATE_SKIN') {
        const newOptions = { ...this.chartOptions }
        newOptions.theme = {
          mode: mutation.payload,
        }
        newOptions.tooltip.theme = mutation.payload
        newOptions.chart.foreColor = mutation.payload === 'dark' ? '#f6f7f8' : '#373d3f'
        this.chartOptions = newOptions
        this.$forceUpdate()
      }
    })
  },
  mounted() {
    const theme = window.localStorage.getItem('vuexy-skin') || 'light'
    const newOptions = { ...this.chartOptions }
    const options = Object.assign(this.defaultOptions, this.options)
    newOptions.grid.xaxis.lines.show = options.xAxysGrid
    newOptions.theme.mode = theme
    newOptions.tooltip.theme = theme
    newOptions.chart.foreColor = theme === 'dark' ? '#f6f7f8' : '#373d3f'
    newOptions.grid.yaxis.lines.show = options.yAxysGrid
    newOptions.chart.zoom.enabled = options.zoom
    newOptions.dataLabels.enabled = options.dataLabels
    this.chartOptions = newOptions
  },

  beforeDestroy() {
    this.unsubscribe = null
  },
}
</script>
