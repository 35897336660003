<template>
  <div>
    <b-card-header>
      <b-card-title>
        <h1>
          Gráficos
        </h1>
      </b-card-title>
    </b-card-header>
    <b-row class="match-height">
      <b-col sm="12" md="12">
        <provisioning-history-graph />
      </b-col>
    </b-row>
    <i2-divider />
    <b-row class="match-height">
      <b-col sm="12" md="12">
        <users-per-day-graph />
      </b-col>
    </b-row>
    <i2-divider />
    <b-row class="match-height">
      <b-col sm="12" md="12">
        <situation-per-organization-graph />
      </b-col>
    </b-row>
    <i2-divider />
  </div>
</template>

<script>

import I2Divider from '@/components/i2/content/i2Divider'
import ProvisioningHistoryGraph from '@/views/graphs/ProvisioningHistoryGraph'
import UsersPerDayGraph from '@/views/graphs/UsersPerDayGraph'
import SituationPerOrganizationGraph from '@/views/graphs/SituationPerOrganization'

import moment from 'moment-timezone'

export default {
  components: {
    SituationPerOrganizationGraph,
    UsersPerDayGraph,
    ProvisioningHistoryGraph,
    I2Divider,

  },
  data: () => ({
    modelMaps: {
      unitsOptions: [],
      unitSelected: { value: null, text: 'Todos' },
      filterDate: '',
      items: [],
      center: { lat: 0, lon: 0 },
    },
    model: {
    },
  }),
  async mounted() {
    const today = new Date()

    this.modelMaps.filterDate = moment(today).subtract(1, 'day').format('DD/MM/YYYY')
    this.getOrganizationUnits()
  },
  methods: {
    getOrganizationUnits() {
      this.modelMaps.unitsOptions = [{
        value: null,
        text: 'Todos',
      }]

      this.modelMaps.unitSelected = {
        value: null,
        text: 'Todos',
      }

      this.$http.get('organization_units').then(response => {
        response.forEach(unit => {
          this.modelMaps.unitsOptions.push(
            {
              value: unit,
              text: unit.path,
            },
          )
        })
        this.getDevicesLocalization()
      })
    },
    getDevicesLocalization() {
      return
      if (!this.modelMaps.filterDate) {
        return
      }
      const unit = this.modelMaps.unitSelected.value ? this.modelMaps.unitSelected.value.uuid : ''
      const filter = `date=${this.modelMaps.filterDate}&unit=${unit}`
      this.$http.get(`devices/localization?${filter}`).then(response => {
        if (!response || response.length === 0) {
          this.modelMaps.center = {
            lat: 0,
            lon: 0,
          }
          this.modelMaps.items = []
          return
        }
        this.modelMaps.center = {
          lat: response[0].geo[0],
          lon: response[0].geo[1],
        }
        response.forEach(device => {
          this.modelMaps.items.push(device)
        })
      })
    },
  },
}
</script>
<style scoped>
.map-viewer-container {
  width: 100%;
  height: 500px;
  z-index: 1 !important;
}
</style>
